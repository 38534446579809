import React from 'react';

export interface MentalAppleSignInButtonProps {
  onClick: () => void,
}

export const MentalAppleSignInButton: React.FC<MentalAppleSignInButtonProps> = ({ onClick }) => {
  return (
    <button
      className="bg-white text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow flex items-center justify-center space-x-2 hover:bg-gray-100 w-64 h-12" // Adjusted width and height here
      onClick={onClick}
    >
      <svg className="h-full w-auto" viewBox="0 0 78 96" xmlns="http://www.w3.org/2000/svg"> {/* Adaptability settings for SVG */}
        <path d="M65.1996 51.2005C65.0996 39.3005 74.9996 33.4005 75.3996 33.2005C69.7996 25.0005 61.0996 23.9005 58.0996 23.8005C50.7996 23.0005 43.6996 28.2005 39.9996 28.2005C36.1996 28.2005 30.4996 23.9005 24.2996 24.0005C16.3996 24.1005 8.89959 28.7005 4.89959 35.8005C-3.5004 50.3005 2.79959 71.7005 10.7996 83.5005C14.7996 89.3005 19.4996 95.7005 25.6996 95.5005C31.6996 95.3005 33.9996 91.7005 41.1996 91.7005C48.3996 91.7005 50.4996 95.5005 56.7996 95.4005C63.2996 95.3005 67.2996 89.6005 71.1996 83.8005C75.8996 77.2005 77.6996 70.7005 77.7996 70.4005C77.6996 70.3005 65.2996 65.5005 65.1996 51.2005Z" fill="#020203" />
        <path d="M53.2996 16.1004C56.4996 12.0004 58.7996 6.50039 58.0996 0.900391C53.3996 1.10039 47.4996 4.1004 44.1996 8.1004C41.1996 11.6004 38.4996 17.3004 39.1996 22.7004C44.4996 23.1004 49.8996 20.1004 53.2996 16.1004Z" fill="#020203" />
      </svg>
      <span>Sign in with Apple</span>
    </button>
  );
};
