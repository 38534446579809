import _ from 'lodash';
import { CountryCodeConfig, fetchPhoneNumbersConfig } from 'models/phone_numbers';
import React from 'react';
import Select, { components, OptionProps, SingleValueProps } from 'react-select';
import grid from 'styles/grid';
import pallette from 'styles/pallette';

interface CountryCodeOption extends CountryCodeConfig {
  long_display: string,
  short_display: string,
  value: string,
  label: string,
}

/* eslint-disable react/destructuring-assignment */
const CustomOption = (props: OptionProps<CountryCodeOption>) => (
  <components.Option {...props}>
    {props.data.long_display}
  </components.Option>
);

const CustomSingleValue = (props: SingleValueProps<CountryCodeOption>) => (
  <components.SingleValue {...props}>
    {props.data.short_display}
  </components.SingleValue>
);

type CountryCodeSelectorProps = {
  onChange: (newCountryCode: CountryCodeConfig | null) => void,
};

const CountryCodeSelector = ({ onChange }: CountryCodeSelectorProps) => {
  const [countryCodeConfigs, setCountryCodeConfigs] = React.useState<CountryCodeConfig[]>([]);
  React.useEffect(() => {
    (async () => {
      const configs = await fetchPhoneNumbersConfig();
      if (configs) {
        setCountryCodeConfigs(configs);
      }
    })();
  }, []);

  const [selectedCountryCode, setSelectedCountryCode] = React.useState<CountryCodeOption | null>(null);
  const countryCodeSelectOptions = React.useMemo(() => {
    return countryCodeConfigs.map((country) => {
      const longDisplay = `${country.flag}  ${country.name}   ${country.country_prefix}`;
      const shortDisplay = `${country.flag}  ${country.country_prefix}`;
      return {
        ...country,
        long_display: longDisplay,
        short_display: shortDisplay,
        value: country.country_code,
        label: longDisplay,
      };
    });
  }, [countryCodeConfigs]);

  React.useEffect(() => {
    onChange(selectedCountryCode);
  }, [selectedCountryCode]);

  const optionForCountryCode = (countryCode: string | undefined): CountryCodeOption | undefined => {
    if (!countryCode) return undefined;
    return _.find(countryCodeSelectOptions, { value: countryCode });
  };

  React.useEffect(() => {
    // split the local e.g. `en-US` to get the country code as the second part
    const localeCountryCode = (() => {
      const languageParts = navigator.language?.split('-');
      if (_.size(languageParts) !== 2) return undefined;
      return languageParts[1]?.toLocaleLowerCase() ?? undefined;
    })();
    // use the locale, otherwise fall back to USA as the default
    const initialValue = optionForCountryCode(localeCountryCode) ?? optionForCountryCode('us');
    if (initialValue) {
      setSelectedCountryCode(initialValue);
    }
  }, [countryCodeConfigs]);

  return (
    <Select
      isMulti={false}
      value={selectedCountryCode}
      onChange={(newValue: CountryCodeOption | null) => {
        setSelectedCountryCode(newValue);
      }}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          height: grid.Custom.d44,
          backgroundColor: pallette.neutral.w800,
          textColor: 'orange',
          paddingLeft: grid.XXSmall,
          borderRadius: grid.Hairline,
          borderTopWidth: 0,
          borderLeftWidth: 0,
          borderRightWidth: 0,
          borderBottomWidth: grid.HairlineThin,
          borderColor: pallette.neutral.w600,
          outline: 'none',
        }),
        indicatorsContainer: (baseStyles, state) => ({
          ...baseStyles,
          borderWidth: 0,
        }),
        input: (baseStyles, state) => ({
          ...baseStyles,
          color: pallette.neutral.w100,
        }),
        menuList: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: pallette.neutral.w100,
          width: grid.PageDimensions.StandardMenuWidth,
        }),
        singleValue: (baseStyles, state) => ({
          ...baseStyles,
          color: pallette.neutral.w100,
          marginRight: grid.XXSmall,
        }),
        dropdownIndicator: (baseStyles, state) => ({
          ...baseStyles,
          color: pallette.neutral.w100,
        }),
      }}
      options={countryCodeSelectOptions}
      components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
    />
  );
};

export default CountryCodeSelector;
