import { trackConversion } from 'components/analytics/useConversionTracking';
import analytics, { AnalyticsEventName } from 'libs/analytics';
import * as CookieStore from 'libs/storage/CookieStore';
import {
  convertRawStripePriceToMentalPaywallPrice,
  createStripeSubscription,
  fetchGuestCheckoutSession,
  linkStripeCheckoutSessionToUser,
  linkStripeCheckoutSessionToUserAsGuest,
  linkStripeSetupIntentSubscriptionToUser,
} from 'models/stripe';
import type { User } from 'models/user';
import Stripe from 'stripe';

export const getTuneTransactionIdFromCookie = (): string | undefined => {
  // This cookie is set from Wordpress Website Script: Create Onboarding Device under Elementor -> Custom Code menu.
  return CookieStore.get('tune_transaction_id');
};

type StrikeCheckoutPurchaseInfo = {
  checkout_method: 'stripe_checkout',
  stripe_checkout_session_id: string,
};
type StripeElementsPurchaseInfo = {
  checkout_method: 'stripe_elements',
  stripe_setup_intent_id: string,
};
type SubscriptionPurchaseInfo = StrikeCheckoutPurchaseInfo | StripeElementsPurchaseInfo;

type HandleSideEffectsParams = {
  deviceId: string,
  email: string | undefined,
  user: User | undefined,
  purchaseInfo: SubscriptionPurchaseInfo,
  subscription_source: SubscriptionSource | undefined,
  deal?: string,
};

export type SubscriptionSource = 'onboarding' | 'lifecycle' | 'therapy_paywall' | 'other';
export const handleSideEffectsAfterSuccessfullySubscribing = async ({
  deviceId, email, user, purchaseInfo, subscription_source, deal,
}: HandleSideEffectsParams) => {
  let price: Stripe.Price | undefined;
  const { checkout_method } = purchaseInfo;
  if (checkout_method === 'stripe_elements') {
    const { stripe_setup_intent_id } = purchaseInfo;
    if (email) {
      const response = await createStripeSubscription({
        email,
        setup_intent_id: stripe_setup_intent_id,
        tune_transaction_id: getTuneTransactionIdFromCookie(),
        deal,
      });
      price = response?.price;
    }
  } else {
    const { stripe_checkout_session_id } = purchaseInfo;
    const response = await fetchGuestCheckoutSession(stripe_checkout_session_id);
    price = response?.price;
  }

  await trackConversionWithPrice({ user, email, price });

  const purchaseEventName: AnalyticsEventName | undefined = (() => {
    if (subscription_source === 'therapy_paywall') return 'Web : Onboarding : Purchase Successful';
    if (subscription_source === 'onboarding') return 'Web : Onboarding : Purchase Successful';
    if (subscription_source === 'lifecycle') return 'Web : Lifecycle : Purchase Successful';
    return undefined;
  })();

  if (purchaseEventName && price) {
    const priceAnalyticsInfo = getPriceAnalyticsInfo(price);

    analytics.trackEvent(purchaseEventName, {
      paywall_type: priceAnalyticsInfo.paywall_type,
      price: analytics.cleanProperties(priceAnalyticsInfo),
    });
  }
};

export const handleSideEffectsAfterLoggingInAfterPurchase = async ({
  deviceId, email, user, purchaseInfo, subscription_source,
}: HandleSideEffectsParams) => {
  const wasGuestPurchase = Boolean(email);
  const tuneTransactionId = getTuneTransactionIdFromCookie();

  const { checkout_method } = purchaseInfo;
  if (checkout_method === 'stripe_checkout') {
    const { stripe_checkout_session_id } = purchaseInfo;
    if (wasGuestPurchase) {
      await linkStripeCheckoutSessionToUserAsGuest(stripe_checkout_session_id);
    } else {
      await linkStripeCheckoutSessionToUser(stripe_checkout_session_id);
    }
  } else if (checkout_method === 'stripe_elements') {
    const { stripe_setup_intent_id } = purchaseInfo;
    await linkStripeSetupIntentSubscriptionToUser(stripe_setup_intent_id, tuneTransactionId);
  }

  if (!user) return;

  if (subscription_source === 'onboarding') {
    analytics.trackEvent('Web : Onboarding : Logged In After Purchase', {
      user_auth_method: user.user_auth_method,
    });
  } else if (subscription_source === 'therapy_paywall') {
    analytics.trackEvent('Web : Onboarding : Logged In After Purchase', {
      user_auth_method: user.user_auth_method,
      paywall_type: 'therapy',
    });
  } else if (subscription_source === 'lifecycle') {
    analytics.trackEvent('Web : Lifecycle : Logged In After Purchase', {
      user_auth_method: user.user_auth_method,
    });
  }
};

type PriceAnalyticsInfo = {
  id: string,
  paywall_type: 'therapy' | undefined,
  lookup_key: string | undefined,
  nickname: string | undefined,
  product: string,
  unit_amount: number | undefined,
  period_type: string | undefined,
  trial_period_days: number,
  is_renewing: boolean | undefined,
};
const getPriceAnalyticsInfo = (price: Stripe.Price): PriceAnalyticsInfo => {
  const mentalPaywallPrice = convertRawStripePriceToMentalPaywallPrice(price);
  return {
    id: price.id,
    paywall_type: mentalPaywallPrice ? 'therapy' : undefined,
    lookup_key: price.lookup_key ?? undefined,
    nickname: price.nickname ?? undefined,
    product: typeof price.product === 'string' ? price.product : price.product.id,
    period_type: mentalPaywallPrice?.period_type ?? undefined,

    // everything before the therapy paywall had a 7-day free trial
    trial_period_days: mentalPaywallPrice?.trial_period_days ?? 7,

    // everything before the therapy paywall was a renewing subscription.
    // will be false for lifetime "subscriptions"
    is_renewing: mentalPaywallPrice?.is_subscription ?? true,

    // in dollars instead of cents
    unit_amount: price.unit_amount ? Math.round((price.unit_amount / 100) * 100) / 100 : undefined,
  };
};

const trackConversionWithPrice = async ({ user, email, price }: { user?: User, email?: string, price: Stripe.Price | undefined }) => {
  if (!price) return;
  const trueEmail = user?.email ?? email;
  if (!trueEmail) return;

  const priceAnalyticsInfo = getPriceAnalyticsInfo(price);
  const amount = priceAnalyticsInfo.unit_amount ?? 0;
  trackConversion(amount, trueEmail, [{
    content_id: priceAnalyticsInfo.id,
    content_type: 'product',
    content_name: priceAnalyticsInfo.nickname,
  }]);
};
