/*
  Logging event to AppsFlyer

  IMPORTANT: Depends on useAppsFlyerScript being called at the parent
*/

import useAppsFlyerScript from 'hooks/useAppsFlyerScript';
import React from 'react';

const useAppsFlyerAnalytics = () => {
  const appsFlyerLoaded = useAppsFlyerScript();

  // ENABLE WHEN NEEDING TEST MODE ON
  // React.useEffect(() => {
  //   if (appsFlyerLoaded) {
  //     window?.AF_SDK?.PLUGINS?.PBA?.turnOnDevMode();
  //   }
  // }, [appsFlyerLoaded]);

  const identifyAppsFlyer = React.useCallback((id: string) => {
    window.AF('pba', 'setCustomerUserId', id);
  }, [appsFlyerLoaded]);

  const logAppsFlyerEvent = React.useCallback((eventName: string, category: string, eventRevenue?: number, extraProps?: any) => {
    window.AF('pba', 'event', {
      eventType: 'EVENT',
      eventName,
      eventRevenue,
      eventValue: {
        category,
        eventValue: {
          label: 'web',
          ...extraProps,
        },
      },
    });
  }, [appsFlyerLoaded]);

  return {
    identifyAppsFlyer,
    logAppsFlyerEvent,
  };
};

export default useAppsFlyerAnalytics;
