import { getExample, parsePhoneNumber } from 'awesome-phonenumber';
import MentalButton from 'components/foundation/buttons/MentalButton';
import Link from 'components/foundation/links/Link';
import MentalText from 'components/foundation/typography/MentalText';
import CountryCodeSelector from 'components/login/CountryCodeSelector';
import analytics from 'libs/analytics';
import { APICallStatus, APIClient, eventPropsForResponse } from 'models/APIClient';
import type { CountryCodeConfig } from 'models/phone_numbers';
import { getUser, postPhoneNumber, User } from 'models/user';
import React from 'react';
import grid from 'styles/grid';
import pallette from 'styles/pallette';

const PhoneNumberAuthEntry = ({ onDidSucceed }: { onDidSucceed: (user: User) => void }) => {
  const [country, setCountry] = React.useState<CountryCodeConfig | null>(null);
  const [formattedPhoneNumber, setFormattedPhoneNumber] = React.useState<string>('');
  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [submissionStatus, setSubmissionStatus] = React.useState<APICallStatus>('unsent');
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

  const phoneNumberLocale = (country?.country_code ?? 'us').toUpperCase();

  const commonEventProps = {
    phone_number_country_name: country?.name,
    phone_number_country_code: country?.country_code,
    phone_number_country_prefix: country?.country_prefix,
    phone_number_is_valid: isValid,
    error_message: errorMessage,
  };

  const placeholderNumber = React.useMemo(() => {
    const exampleNumber = getExample(phoneNumberLocale).getNumber();
    return parsePhoneNumber(exampleNumber, phoneNumberLocale).getNumber('national');
  }, [country]);

  const onUpdatePhoneNumber = (phoneNumberText: string) => {
    const number = parsePhoneNumber(phoneNumberText, phoneNumberLocale);
    setFormattedPhoneNumber(number.getNumber('national'));
    setIsValid(number.isValid());
    setSubmissionStatus('unsent');
  };

  React.useEffect(() => {
    // reformat for the new country code
    onUpdatePhoneNumber(formattedPhoneNumber ?? '');
  }, [country]);

  const canSend = () => {
    return submissionStatus === 'unsent' || submissionStatus === 'error';
  };

  const postPhoneNumberToAPI = async () => {
    if (!isValid) return;
    if (!canSend()) return;
    if (!country) return;

    setSubmissionStatus('sending');
    const number = parsePhoneNumber(formattedPhoneNumber, phoneNumberLocale);
    const rawInternationalNumber = number.getNumber('e164');
    if (!rawInternationalNumber.startsWith(country.country_prefix)) {
      // TODO: Inform the user? Log an error? How to proceed here?
      return;
    }

    const eventProps = {
      ...commonEventProps,
      phone_number: rawInternationalNumber,
    };

    analytics.trackEvent('Phone Number Auth : Entry : Submitted', eventProps);
    analytics.updateUserProperties({
      phone_number: rawInternationalNumber,
      phone_number_country_code: country.country_code,
      phone_number_country_name: country.name,
      phone_number_country_prefix: country.country_prefix,
    });

    const response = await postPhoneNumber({
      phone_number: {
        phone_number: rawInternationalNumber,
        country_code: country.country_code,
        country_prefix: country.country_prefix,
      },
    });

    const responseProps = {
      ...eventProps,
      ...eventPropsForResponse(response),
    };

    if (APIClient.didSucceed(response)) {
      const user = await getUser();
      if (!user) {
        // TODO: Weird case, should be impossible
        return;
      }

      const user_is_new = response.successRes.data.user_is_new ?? false;
      analytics.trackEvent('Phone Number Auth : Entry : Succeeded', {
        ...responseProps,
        user_is_new,
      });

      setSubmissionStatus('success');

      onDidSucceed(user);
    } else {
      analytics.trackEvent('Phone Number Auth : Entry : Failed', responseProps);

      setSubmissionStatus('error');
      setErrorMessage(response.frontendErrorMessage);
    }
  };

  // useDidUpdateEffect(() => {
  //   MentalAnalytics.trackEvent('Phone Number Auth : Entry : Country Selector : Country : Chosen', commonEventProps);
  // }, country?.country_code);

  return (
    <div style={{
      maxWidth: 0.5 * grid.PageDimensions.InnerContent,
    }}
    >
      <MentalText.Subhead2 color={pallette.neutral.w100} style={{ marginBottom: grid.XSmall }}>
        What’s your phone number?
      </MentalText.Subhead2>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
      }}
      >
        <CountryCodeSelector
          onChange={(newValue) => {
            setCountry(newValue);
          }}
        />
        <input
          value={formattedPhoneNumber}
          onChange={(e) => {
            onUpdatePhoneNumber(e.target.value);
          }}
          placeholder={placeholderNumber}
          style={{
            maxWidth: grid.XXXXLarge,
            marginLeft: grid.XXSmall,
            height: grid.Custom.d44,
            backgroundColor: pallette.neutral.w800,
            color: pallette.neutral.w100,
            paddingLeft: grid.XXSmall,
            borderRadius: grid.Hairline,
            borderBottomWidth: grid.HairlineThin,
            borderColor: pallette.neutral.w600,
            outline: 'none',
          }}
        />
        {
          (errorMessage && submissionStatus === 'error') ? (
            <div
              color={pallette.accent.error}
              style={{
                marginTop: grid.Small,
                textAlign: 'center',
              }}
            >
              {errorMessage}
            </div>
          ) : null
        }
      </div>
      <MentalText.Paragraph color={pallette.neutral.w300} style={{ marginTop: grid.XSmall }}>
        Make sure you can receive SMS to this number
      </MentalText.Paragraph>
      <MentalText.ParagraphSmall color={pallette.neutral.w500} style={{ marginTop: grid.Small, maxWidth: 300 }}>
        By tapping submit, you’re agreeing to our{' '}
        <Link href="https://www.getmental.com/legal/terms">Terms&nbsp;of&nbsp;Service</Link> and <Link href="https://www.getmental.com/legal/privacy">Privacy&nbsp;Policy</Link>
      </MentalText.ParagraphSmall>
      <div>
        <div>
          <div style={{ marginTop: grid.XLarge }}>
            <MentalButton
              role="primary"
              disabled={!isValid}
              title={canSend() ? 'Submit' : undefined}
              loading={submissionStatus === 'sending'}
              onPress={() => {
                postPhoneNumberToAPI();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PhoneNumberAuthEntry;
