import React from 'react';
import pallette from 'styles/pallette';

const Link = ({ href, children }: { href: string, children: React.ReactNode }) => {
  return (
    <a href={href} target="_blank" style={{ color: pallette.accent.link }} rel="noreferrer">
      {children}
    </a>
  );
};
export default Link;
