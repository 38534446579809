import Color from 'color';
import { trackFacebookEvent } from 'components/analytics/FacebookPixel';
import { identifyTikTokPixel } from 'components/analytics/TikTokPixel';
import Header from 'components/foundation/bars/Header';
import DefaultHead from 'components/foundation/head/DefaultHead';
import MentalText from 'components/foundation/typography/MentalText';
import Login from 'components/login/Login';
import useAPIPage from 'hooks/useAPIPage';
import analytics from 'libs/analytics';
import * as CookieStore from 'libs/storage/CookieStore';
import { parseQueryParamAsOptionalString } from 'libs/url';
import { getDeviceIdAndSources } from 'models/device_id';
import { handleSideEffectsAfterSuccessfullySubscribing, SubscriptionSource } from 'models/subscription';
import { User } from 'models/user';
import type { GetServerSideProps, GetServerSidePropsContext, NextPage } from 'next';
import { useRouter } from 'next/router';
import React from 'react';
import grid from 'styles/grid';
import pallette from 'styles/pallette';

type LoginPageProps = {
  source: string | null,
  destination: string | null,
  email: string | null,

  subscription_source: SubscriptionSource | null,
  stripe_checkout_session_id: string | null,
  stripe_setup_intent_id: string | null,
};

const LoginPage: NextPage<LoginPageProps> = ({
  source,
  destination,
  email,
  subscription_source,
  stripe_checkout_session_id,
  stripe_setup_intent_id,
}) => {
  useAPIPage();
  const router = useRouter();

  React.useEffect(() => {
    if (email) {
      identifyTikTokPixel({ email });
    }
  }, [email]);

  React.useEffect(() => {
    if (subscription_source === 'onboarding') {
      // If the user is coming from web2web wordpress page
      analytics.trackEvent('Web : Onboarding : Login Viewed');
    } else if (subscription_source === 'therapy_paywall') {
      analytics.trackEvent('Web : Onboarding : Login Viewed', { paywall_type: 'therapy' });
    } else if (subscription_source === 'lifecycle') {
      analytics.trackEvent('Web : Lifecycle : Login Viewed');
    }
  }, [subscription_source]);

  React.useEffect(() => {
    const purchaseInfo = (() => {
      if (stripe_checkout_session_id) {
        return { checkout_method: 'stripe_checkout' as 'stripe_checkout', stripe_checkout_session_id };
      }
      if (stripe_setup_intent_id) {
        return { checkout_method: 'stripe_elements' as 'stripe_elements', stripe_setup_intent_id };
      }
      return undefined;
    })();
    if (!purchaseInfo) return;
    handleSideEffectsAfterSuccessfullySubscribing({
      deviceId: getDeviceIdAndSources().deviceId,
      email: email ?? undefined,
      user: undefined,
      purchaseInfo,
      subscription_source: subscription_source ?? undefined,
      deal: CookieStore.get('deal'),
    });
  }, []);

  const headerTitle = (() => {
    if (source === '/subscribe') {
      return 'Start Your Free Trial';
    }
  })();

  const pageBackgroundColorDark = Color(pallette.neutral.w900).darken(0.4);
  const pageBackgroundColorLight = Color(pallette.neutral.w900).darken(0.1);

  const afterLoginAnalytics = async (user: User) => {
    if (user) {
      const { deviceId } = getDeviceIdAndSources();
      if (subscription_source === 'onboarding' || subscription_source === 'therapy_paywall') {
        trackFacebookEvent('Web : Onboarding : Login Succesfull', deviceId);
      }
    }
  };

  return (
    <>
      <DefaultHead title="Mental — Log in" />
      <main style={{ overflowX: 'hidden' }}>
        <div style={{
          height: '100vh',
          position: 'relative',
          background: `linear-gradient(-30deg, ${pageBackgroundColorDark.toString()}, ${pageBackgroundColorLight.toString()})`,
        }}
        >
          <div style={{
            position: 'absolute',
            zIndex: 0,
            display: 'flex',
            justifyContent: 'center',
            width: '100vw',
          }}
          >
            <img
              src="/images/mental-app-phone-001.png"
              style={{
                position: 'relative',
                transform: 'translateX(520px) rotate(-30deg)',
                top: 10,
              }}
            />
          </div>
          <div style={{ position: 'relative' }}>
            <Header />
            <div>
              {headerTitle ? (
                <MentalText.Superheading1
                  color={pallette.neutral.w100}
                  isAccentFont
                  centered
                  style={{
                    paddingTop: grid.LargePlus,
                    paddingBottom: grid.Large,
                    paddingLeft: grid.XSmall,
                    paddingRight: grid.XSmall,
                  }}
                >
                  {headerTitle}
                </MentalText.Superheading1>
              ) : <div style={{ height: grid.Large }} />}
              <Login onDidLogIn={async (user: User) => {
                await afterLoginAnalytics(user);

                // We wait a moment to make sure all in-flight events and backend calls have a chance to complete
                const delayToAllowEventsToFire = 1000;
                setTimeout(() => {
                  const hasQueryParams = destination?.includes('?');
                  const searchParams = window.location.search.substring(1); // Remove the leading '?'
                  const redirectDestination = (() => {
                    if (destination) {
                      if (hasQueryParams) {
                        return `${destination}&${searchParams}`;
                      }
                      return `${destination}?${searchParams}`;
                    }
                    return '/account';
                  })();
                  router.push(redirectDestination);
                }, delayToAllowEventsToFire);
              }}
              />
            </div>
          </div>
        </div>

      </main>
    </>
  );
};

export const getServerSideProps: GetServerSideProps<LoginPageProps> = async (context: GetServerSidePropsContext) => {
  return {
    props: {
      email: parseQueryParamAsOptionalString(context.query.email),
      source: parseQueryParamAsOptionalString(context.query.source),
      destination: parseQueryParamAsOptionalString(context.query.destination),

      // These query params come from getPurchaseSuccessRedirectUrl on the API server
      subscription_source: parseQueryParamAsOptionalString(context.query.subscription_source),
      stripe_checkout_session_id: parseQueryParamAsOptionalString(context.query.stripe_checkout_session_id),
      stripe_setup_intent_id: parseQueryParamAsOptionalString(context.query.stripe_setup_intent_id),
    },
  };
};

export default LoginPage;
