import React from 'react';
import pallette from 'styles/pallette';

const LinkButton = ({ onClick, children }: { onClick: () => void, children: React.ReactNode }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      style={{
        background: 'none',
        border: 'none',
        padding: 0,
        margin: 0,
        cursor: 'pointer',
        color: pallette.accent.link,
      }}
    >
      {children}
    </button>
  );
};
export default LinkButton;
