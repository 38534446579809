import * as Sentry from '@sentry/nextjs';
import { trackFacebookEvent } from 'components/analytics/FacebookPixel';
import { trackGAdsConversionEvent } from 'components/analytics/GTag';
import { TikTokEventContent, trackTikTokEvent } from 'components/analytics/TikTokPixel';
import { trackXEvent, XEventContent } from 'components/analytics/XPixel';
import analytics, { AnalyticsProperties } from 'libs/analytics';
import { getDeviceIdAndSources } from 'models/device_id';

const XPurchaseEventId = 'tw-on7js-on7k1';

const gAdsEventId = 'AW-11123463471/tT_qCJf_s8kZEK-qirgp';

// Tracks all conversion events for ADS campaigns.
export const trackConversion = (amount: number, email: string, contents?: TikTokEventContent[] | XEventContent[]) => {
  try {
    const { deviceId } = getDeviceIdAndSources();

    // Track FB pixel purchase
    const facebookPixelData = {
      currency: 'USD',
      value: amount,
      email,
      user_data: {
        email,
      },
      custom_data: {
        currency: 'USD',
        value: amount,
      },
    };
    const facebookPixelResponse = trackFacebookEvent('Purchase', deviceId, facebookPixelData);

    if (facebookPixelResponse) {
      trackPixelAnalytics('Facebook', { eventName: 'Purchase', ...facebookPixelData } as AnalyticsProperties);
    }

    // Track TikTok pixel purchase
    const tikTokPixelData = {
      currency: 'USD',
      value: amount,
      email,
      contents,
    };
    const tikTokPixelResponse = trackTikTokEvent('Purchase', tikTokPixelData);

    if (tikTokPixelResponse) {
      trackPixelAnalytics('TikTok', { eventName: 'Purchase', ...tikTokPixelData } as AnalyticsProperties);
    }

    // X Ads Pixel Conversion
    const xPixelData = {
      currency: 'USD',
      value: amount,
      email_address: email,
      contents,
    };
    const xPixelResponse = trackXEvent(XPurchaseEventId, xPixelData);

    if (xPixelResponse) {
      trackPixelAnalytics('X', { eventName: XPurchaseEventId, ...xPixelData } as AnalyticsProperties);
    }

    // Google Ads Conversion Tracking
    const gAdsData = {
      transaction_id: deviceId,
      value: amount,
      currency: 'USD',
    };
    const googleAdsResponse = trackGAdsConversionEvent(gAdsEventId, gAdsData);

    if (googleAdsResponse) {
      trackPixelAnalytics('Google Ads', { eventName: gAdsEventId, ...gAdsData } as AnalyticsProperties);
    }
  } catch (error) {
    Sentry.captureException(error);
  }
};

const trackPixelAnalytics = (pixelName: string, parameters: AnalyticsProperties) => {
  try {
    analytics.trackEvent('Web : Pixel : Conversion', {
      platform: pixelName,
      ...parameters,
    });
  } catch (error) {
    Sentry.captureException(error);
  }
};
