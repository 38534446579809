import { parsePhoneNumber } from 'awesome-phonenumber';
import LinkButton from 'components/foundation/links/LinkButton';
import MentalSpinnerView from 'components/foundation/progress/MentalSpinnerView';
import MentalText from 'components/foundation/typography/MentalText';
// import { useUnverifiedUser } from 'hooks/useUser';
import analytics from 'libs/analytics';
import { stripNonnumerics } from 'libs/formatting';
import { APICallStatus, APIClient, eventPropsForResponse } from 'models/APIClient';
import { resendVerificationCode, useUser, verifyPhoneNumber } from 'models/user';
import React from 'react';
import grid from 'styles/grid';
import pallette from 'styles/pallette';

const CodeDigitsConfig = {
  numDigits: 6,
  dimensions: {
    width: grid.MediumPlus,
    gap: grid.XXSmall,
  },
};

type CodeCallback = (code: string) => void;
const CodeInputElement = ({ onCompletion, onChange }: { onCompletion: CodeCallback, onChange: CodeCallback }) => {
  const [code, setCode] = React.useState('');

  const textInputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    textInputRef.current?.focus();
  }, []);

  const numericCode = stripNonnumerics(code);

  React.useEffect(() => {
    if (numericCode.length === CodeDigitsConfig.numDigits) {
      onCompletion(numericCode);
    }
    onChange(numericCode);
  }, [numericCode]);

  const fullInputWidth = CodeDigitsConfig.numDigits * (CodeDigitsConfig.dimensions.width + CodeDigitsConfig.dimensions.gap) - CodeDigitsConfig.dimensions.gap;
  const bufferX = 24;
  const shiftX = 9;
  const letterSpacing = 23.5;

  return (
    <div
      style={{
        marginBottom: grid.Large,
        textAlign: 'center',
      }}
    >
      <input
        ref={textInputRef}
        autoFocus
        onChange={(event) => {
          setCode(event.target.value);
        }}
        style={{
          fontFamily: 'ChivoMono, mono',
          fontSize: 40,
          backgroundColor: pallette.clear,
          border: 'none',
          height: grid.Large,
          margin: '0 auto',
          color: pallette.neutral.w100,
          letterSpacing,
          paddingLeft: shiftX + bufferX,
          width: fullInputWidth + 2 * bufferX,
          outline: 'none',
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          marginTop: grid.XXSmall,
        }}
      >
        {
          Array.from(Array(CodeDigitsConfig.numDigits).keys()).map((index) => (
            <div
              key={index}
              style={{
                backgroundColor: pallette.neutral.w600,
                width: CodeDigitsConfig.dimensions.width,
                height: grid.Hairline,
                marginRight: (() => {
                  if (index === CodeDigitsConfig.numDigits - 1) return 0;
                  return CodeDigitsConfig.dimensions.gap;
                })(),
              }}
            />
          ))
        }
      </div>
    </div>
  );
};

const PhoneNumberVerificationCodeEntry = ({ onDidClickWrongNumber, onDidVerify }: { onDidClickWrongNumber: () => void, onDidVerify: () => void }) => {
  const [verificationCode, setVerificationCode] = React.useState<string>('');
  const [submissionStatus, setSubmissionStatus] = React.useState<APICallStatus>('unsent');
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const [hasResendDelayElapsed, setHasResendDelayElapsed] = React.useState<boolean>(false);

  const user = useUser();

  const commonEventProps = {
    error_message: errorMessage,
  };

  React.useEffect(() => {
    analytics.trackEvent('Phone Number Auth : Verification : Viewed', commonEventProps);
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      setHasResendDelayElapsed(true);
    }, 7000);
  }, []);

  const onCompleteVerificationCode = (verificationCodeText: string) => {
    setSubmissionStatus('unsent');
    setVerificationCode(verificationCodeText);
  };

  const verificationCodeIsValid = () => {
    return new RegExp(`^\\d{${CodeDigitsConfig.numDigits}}$`).test(verificationCode);
  };

  const postVerificationCodeToAPI = async () => {
    if (submissionStatus !== 'unsent') return;
    if (!verificationCodeIsValid()) return;

    analytics.trackEvent('Phone Number Auth : Verification : Submitted', commonEventProps);

    setSubmissionStatus('sending');

    const response = await verifyPhoneNumber(verificationCode);

    const responseProps = {
      ...commonEventProps,
      ...eventPropsForResponse(response),
    };

    if (APIClient.didSucceed(response)) {
      analytics.trackEvent('Phone Number Auth : Verification : Succeeded', responseProps);
      setSubmissionStatus('success');
      onDidVerify();
    } else {
      analytics.trackEvent('Phone Number Auth : Verification : Failed', responseProps);
      setSubmissionStatus('error');
      setErrorMessage(response.frontendErrorMessage);
    }
  };

  React.useEffect(() => {
    if (verificationCodeIsValid()) {
      postVerificationCodeToAPI();
    }
  }, [verificationCode]);

  const formattedPhoneNumber = (() => {
    if (!user || !user?.phone_number) return undefined;
    const number = parsePhoneNumber(user.phone_number);
    return number.getNumber('international');
  })();

  const resendCode = async () => {
    analytics.trackEvent('Phone Number Auth : Verification : Resend Code : Submitted', commonEventProps);
    setSubmissionStatus('sending');
    setVerificationCode('');

    const response = await resendVerificationCode();
    const responseProps = {
      ...commonEventProps,
      ...eventPropsForResponse(response),
    };

    if (APIClient.didSucceed(response)) {
      analytics.trackEvent('Phone Number Auth : Verification : Resend Code : Succeeded', responseProps);
      setSubmissionStatus('unsent');
    } else {
      analytics.trackEvent('Phone Number Auth : Verification : Resend Code : Failed', responseProps);
      setSubmissionStatus('error');
      setErrorMessage(response.frontendErrorMessage);
    }
  };

  const clearError = () => {
    setSubmissionStatus('unsent');
    setErrorMessage(undefined);
    setVerificationCode('');
  };

  return (
    <div>
      {
        formattedPhoneNumber ? (
          <MentalText.Subhead2 style={{
            // textAlign: 'center',
            color: pallette.lightText,
            marginTop: grid.Small,
            // marginHorizontal: grid.GuttersX,
          }}
          >
            We’ve sent a code to your phone number <span style={{ fontWeight: 800 }}>{formattedPhoneNumber.replace(/ /g, '\u00A0')}</span>
          </MentalText.Subhead2>
        ) : null
      }
      <MentalText.Heading2
        bold
        centered
        style={{
          marginTop: grid.Small,
          marginBottom: grid.XXSmall,
          color: pallette.lightText,
        }}
      >
        Enter your code
      </MentalText.Heading2>
      <div>
        <CodeInputElement
          onChange={() => {
            if (submissionStatus === 'error') {
              clearError();
            }
          }}
          onCompletion={(code) => {
            onCompleteVerificationCode(code);
          }}
        />
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        >
          {
            submissionStatus === 'sending'
              ? (
                <MentalSpinnerView dimension={grid.Large} mode="light" />
              )
              : (
                <>
                  {(errorMessage && submissionStatus === 'error') ? (
                    <MentalText.Paragraph
                      bold
                      style={{
                        textAlign: 'center',
                        color: pallette.accent.error,
                        marginBottom: grid.Medium,
                      }}
                    >{errorMessage}
                    </MentalText.Paragraph>
                  ) : null}
                  <MentalText.Paragraph style={{ marginBottom: grid.Medium }}>
                    <LinkButton
                      onClick={() => {
                        analytics.trackEvent('Phone Number Auth : Verification : Wrong Number : Clicked', commonEventProps);
                        onDidClickWrongNumber();
                      // TODO: Go Back to the phone number with a callback function
                      // navigation.goBack();
                      }}
                    >
                      Wrong number?
                    </LinkButton>
                  </MentalText.Paragraph>
                  {
                    hasResendDelayElapsed ? (
                      <MentalText.Paragraph style={{ marginBottom: grid.Medium }}>
                        <LinkButton
                          onClick={() => {
                            resendCode();
                          }}
                        >
                          Resend code
                        </LinkButton>
                      </MentalText.Paragraph>
                    ) : null
                  }
                </>
              )
          }
        </div>
      </div>
    </div>
  );
};

export default PhoneNumberVerificationCodeEntry;
