import { APIClient } from 'models/APIClient';

export interface CountryCodeConfig {
  name: string,
  country_code: string,
  country_prefix: string,
  flag: string,
}

export const fetchPhoneNumbersConfig = async (): Promise<CountryCodeConfig[] | undefined> => {
  const response = await APIClient.get<{ country_codes: CountryCodeConfig[] }>('/config/phone_numbers', undefined, undefined);
  if (APIClient.didSucceed(response)) {
    return response.successRes.data.country_codes;
  }
  return undefined;
};
