import { useGoogleLogin } from '@react-oauth/google';
import * as Sentry from '@sentry/nextjs';
import { identifyTikTokPixel } from 'components/analytics/TikTokPixel';
import { MentalAppleSignInButton, MentalAppleSignInButtonProps } from 'components/foundation/buttons/MentalAppleSignInButton';
import { MentalGoogleSignInButton } from 'components/foundation/buttons/MentalGoogleSignInButton';
import MentalText from 'components/foundation/typography/MentalText';
import PhoneNumberAuthEntry from 'components/login/PhoneNumberAuthEntry';
import PhoneNumberVerificationCodeEntry from 'components/login/PhoneNumberVerificationCodeEntry';
import useAppsFlyerAnalytics from 'hooks/useAppsFlyerAnalytics';
import analytics from 'libs/analytics';
import * as CookieStore from 'libs/storage/CookieStore';
import { getDeviceId } from 'models/device_id';
import { fetchGoogleUserInfo, getVerifiedUser, User } from 'models/user';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import AppleSignin from 'react-apple-signin-auth';
import grid from 'styles/grid';
import pallette from 'styles/pallette';
import { UAParser } from 'ua-parser-js';

const Login = ({
  onDidLogIn,
}: {
  onDidLogIn: (user: User) => void,
}) => {
  const [user, setUser] = React.useState<User | null>(null);
  const router = useRouter();

  const appsFlyer = useAppsFlyerAnalytics();

  // See https://linear.app/mental/issue/MNTL-2080/fix-bug-with-google-login-on-web-after-purchase
  // Instagram and Facebook browsers do not support the Google OAuth2 flow, so we need to prevent them from being used
  // Othwerwise we're losing people on web, causing dropoff as well as disputes etc. Better to have them use Phone Number to login.
  // Hopefully this is temporary, but it's very difficult to get to a root-cause fix because these are embedded browsers that are (very) hard to debug in.
  const canSupportGoogleAuth = React.useMemo<boolean>(() => {
    const userAgent = new UAParser().getResult();
    const browserName = userAgent.browser.name?.toLowerCase().trim();
    if (browserName === 'instagram' || browserName === 'facebook') return false;
    return true;
  }, []);

  const isFromOnboarding = router.query?.subscription_source === 'onboarding';
  const isFromTherapyPaywall = router.query?.subscription_source === 'therapy_paywall';

  const isFromMobileApp = router.query?.is_from_mobile_app;

  const [mode, setMode] = React.useState<'phone_number' | 'auth_code'>('phone_number');

  // if the user is already logged in before loading the component, immediately call the onDidLogIn callback
  const refetchUser = async () => {
    setFetchUserCount((x) => x + 1);
  };

  const [fetchUserCount, setFetchUserCount] = React.useState<number>(0);

  React.useEffect(() => {
    saveCookieForAppleRedirect();
    (async () => {
      const fetchedUser = await getVerifiedUser();
      if (fetchedUser) {
        setUser(fetchedUser);
      }
    })();
  }, [fetchUserCount]);

  const handleDidLogInIfVerified = () => {
    if (!user?.is_verified) return;

    analytics.identifyUser(user);

    identifyTikTokPixel({
      mental_user_id: user.id,
      phone_number: user.phone_number,
      email: user.email,
    });

    // identify user on apps flyer
    appsFlyer?.identifyAppsFlyer(user.id);

    onDidLogIn(user);
  };

  React.useEffect(() => {
    handleDidLogInIfVerified();
  }, [user]);

  const renderInnerContents = () => {
    if (mode === 'phone_number') {
      return (
        <PhoneNumberAuthEntry
          onDidSucceed={(newUser: User) => {
            setUser(newUser);
            setMode('auth_code');
          }}
        />
      );
    }
    return (
      <PhoneNumberVerificationCodeEntry
        onDidClickWrongNumber={() => {
          setMode('phone_number');
        }}
        onDidVerify={() => {
          refetchUser();
        }}
      />
    );
  };

  const [googleUser, setGoogleUser] = useState<{ access_token: string } | null>(null);

  useEffect(() => {
    if (!canSupportGoogleAuth) return;

    (async () => {
      if (googleUser) {
        await fetchGoogleUserInfo(googleUser);
        refetchUser();
      }
    })();
  }, [googleUser, canSupportGoogleAuth]);

  const doGoogleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setGoogleUser(codeResponse);
    },
    onError: (error) => {
      Sentry.captureException(error);
    },
  });

  const saveCookieForAppleRedirect = async () => {
    try {
      const { destination, ...otherParams } = router.query;

      if (destination) {
        const deviceId = getDeviceId();
        // Parse the destination URL to handle existing query params
        const [destinationBaseUrl, destinationExistingParams] = (destination as string).split('?');
        const destinationQueryParams = new URLSearchParams(destinationExistingParams || '');

        // Add additional params
        Object.entries(otherParams).forEach(([key, value]) => {
          destinationQueryParams.set(key, value as string);
        });
        destinationQueryParams.set('mental_device_id', deviceId);

        const url = `${destinationBaseUrl}?${destinationQueryParams.toString()}`;
        await CookieStore.set('apple_redirect_to_after_success', url, { domain: '.getmental.com' });
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  return (
    <>
      {(isFromOnboarding || isFromTherapyPaywall) && (
        <div className="flex justify-center">
          <MentalText.Heading1
            bold
            isAccentFont
            color={pallette.neutral.w100}
            style={{ marginBottom: grid.Small }}
          >
            Final Step
          </MentalText.Heading1>
        </div>
      )}
      <div
        style={{
          margin: 'auto',
          justifyContent: 'center',
          backgroundColor: pallette.neutral.w1000,
          borderRadius: grid.XSmall,
          paddingTop: grid.MediumPlus,
          paddingBottom: grid.MediumPlus,
          paddingLeft: grid.Medium,
          paddingRight: grid.Medium,
        }}
        className="flex w-full lg:w-1/2"
      >
        <div
          style={{
            marginBottom: grid.XXLarge,
          }}
          className="flex flex-col"
        >
          <MentalText.Heading2
            bold
            color={pallette.neutral.w100}
            style={{ marginBottom: grid.Small }}
          >
            {(() => {
              if (isFromMobileApp) return 'Log in to continue';
              if (isFromTherapyPaywall) return 'To access Mental Pro, you MUST create an account now';
              if (isFromOnboarding) return 'To access your free trial, you MUST create an account now';
              return 'Let’s create your account';
            })()}
          </MentalText.Heading2>
          <div className="flex flex-col flex-col-reverse items-center md:items-start md:flex-row">
            <div className="pr-10">
              {renderInnerContents()}
            </div>

            {mode !== 'auth_code' && (
              <div className="flex flex-col mb-10">
                <div className="hidden md:block">
                  <MentalText.Subhead2 color={pallette.neutral.w100} style={{ marginBottom: grid.XSmall }}>
                    Or
                  </MentalText.Subhead2>
                </div>

                {
                  canSupportGoogleAuth ? (
                    <div className="mb-4">
                      <MentalGoogleSignInButton onClick={() => doGoogleLogin()} />
                    </div>
                  ) : null
                }

                <AppleSignin
                  authOptions={{
                    clientId: process.env.NEXT_PUBLIC_APPLE_OAUTH_CLIENT_ID ?? '',
                    scope: 'email name',
                    redirectURI: process.env.NEXT_PUBLIC_APPLE_OAUTH_REDIRECT_URI ?? '',
                    state: 'state',
                    nonce: 'nonce',
                    usePopup: false,
                  }}
                  uiType="dark"
                  noDefaultStyle={false}
                  onSuccess={() => {
                  }}
                  onError={(error: any) => Sentry.captureException(error)}
                  skipScript={false}
                  // eslint-disable-next-line no-undef
                  render={(props: JSX.IntrinsicAttributes & MentalAppleSignInButtonProps) => <MentalAppleSignInButton {...props} />}
                />
              </div>
            )}
          </div>
        </div>

      </div>
    </>
  );
};
export default Login;
